import React, { Component } from 'reactn'
import axios from 'axios';
// import { Animated } from "react-animated-css";
import badge from '../../images/badge.png'
import loading from '../../images/grid.svg'

class Popup extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.emailInput = React.createRef();
		this.state = {
			email: '',
			advice: false,
			submitDisabled: false
		}
    }

	componentDidUpdate() {
		// -- Code to focus on the email input when popup is active --
		// if(this.global.input_focus !== 'email' && this.global.show_popup) {
		// 	setTimeout(function() {
		// 		console.debug(this.emailInput.current);
		// 		this.emailInput.current.focus();
		// 		this.setGlobal({
		// 			input_focus: 'email'
		// 		})
		// 	}.bind(this), 1000);
		// }
	}

	handleChange(event) {
		const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
		this.setState({
			...this.state,
            [event.target.name]: value
        })
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setState({
			...this.state,
            submitDisabled: true
        })
		this.setGlobal({
			email: this.state.email,
			advice_checked: this.state.advice,
			submitted: true
		})
		setTimeout(function() {
			this.setGlobal({
				show_popup_form: false,
				show_popup_submitted: true
			})
		}.bind(this), 500);

		axios({
			method: 'post',
			url: 'https://easygoseo.com/mail.php',
			headers: { 'content-type': 'application/json' },
			data: this.global
		})
		.then(result => {
			console.debug(result)
		})
		.catch(error => {
			console.debug(error)
		});

	}

	closePopup() {
		this.setGlobal({
			show_popup: false,
			show_popup_form: false,
			show_popup_submitted: false,
			input_focus: 'website'
		})
		this.setState({
			...this.state,
            submitDisabled: false
        })
	}

	render() {
		return (
			<div id="popup" style={this.global.show_popup ? { visibility : 'visible' } : { visibility : 'hidden' } }>
				<form>
				<div className="box">
					<div className="badge xhide--mobile">
						<img src={badge} alt="" />
					</div>
					<div className="form" style={this.global.show_popup_form ? { visibility : 'visible' } : { visibility : 'hidden' }} >
						<p className="header">We'll send your results to the email below</p>
						<input type="text" name="email" placeholder="your@email.com" value={this.state.email} onChange={this.handleChange} ref={this.emailInput} />
						<div className="checkbox">
							<div className="input">
								<input name="advice" type="checkbox" checked={this.state.advice} onChange={this.handleChange} />
							</div>
							<div className="text">
								<p>Tick this box if you'd like to receive free personalised advice from an SEO expert</p>
							</div>
							<div className="pro hide--mobile">
								<p>Pro</p>
							</div>
						</div>
						{ /* <p className="small">Your information will only ever be used for the services you've chosen above.<span className="hide--mobile"> We value and protect your personal and business data.</span></p> */}
						<button type="submit" className="final-click-button" onClick={this.handleSubmit} disabled={this.state.submitDisabled}>Get My Results</button>
					</div>
					<div className="submitted" style={this.global.show_popup_submitted ? { visibility : 'visible' } : { visibility : 'hidden' }} >
						<span>
							<p className="header">Your report is being generated</p>
							<img src={loading} alt="" />
							<p className="small">We'll send the report to the email you provided as soon as it's complete. You can now close this window.</p>
						</span>
					</div>
				</div>
				</form>
				<div className="bg-fade" onClick={this.closePopup}></div>
			</div>
		)
	}
}

export default Popup
