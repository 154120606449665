import React, { Component } from 'reactn'

class Search extends Component {

	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.websiteInput = React.createRef();
		this.state = { website: '' }
    }

	componentDidMount() {
		// -- Code to focus on website input on first load --
		// this.websiteInput.current.focus();
	}

	handleChange(event) {
		this.setState({
            [event.target.name]: event.target.value
        })
	}

	handleSubmit(event) {
		event.preventDefault();
		this.setGlobal({
			website: this.state.website,
			show_popup: true,
			show_popup_form: true
		})
	}

	render() {
		return (
			<div id="search">
				<form>
				<input type="text" name="website" placeholder="your-website.com" value={this.state.website} onChange={this.handleChange} ref={this.websiteInput} />
				<button type="submit" className="initial-click-button" onClick={this.handleSubmit}><span>Buy &pound;29.99</span> FREE</button>
				</form>
			</div>
		)
	}
}

export default Search
