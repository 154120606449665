import React, { Component } from 'reactn'
import logo from '../../images/logo1.png'

class Nav extends Component {

	render() {
		return (
			<div id="nav">
				<span className="logo"><img src={logo} alt="EasyGoSEO" /></span>
			</div>
		)
	}
}

export default Nav
