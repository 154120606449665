import React, { Component } from "reactn"
import ConfettiGenerator from "confetti-js"
import './reset.scss'
import './App.scss'
import preview from './images/report-preview.png'
// import reportRings from './images/report-rings.png'
// import reportRings from './images/report-rings2.png'

import Nav from './components/Nav/'
import Search from './components/Search/'
import Popup from './components/Popup/'

class App extends Component {

	componentWillMount() {
		this.setGlobal({
			show_popup: false,
			show_popup_form: false,
			show_popup_submitted: false,
			website: '',
			email: '',
			advice_checked: false,
			input_focus: 'website',
			submitted: false
		});
	}

	componentDidMount() {
		const confettiSettings = { target: 'confetti', clock: '5', max: '15'};
		const confetti = new ConfettiGenerator(confettiSettings);
		confetti.render();
		//return () => confetti.clear();
	}

	render() {
		return (
			<div id="home">
				<Popup />
				<Nav />
				<div className="wrapper">
					<h1><span className="hide--mobile">Super </span>Simple<span className="dot">.</span> <span className="hide--mobile">Super </span>Fast<span className="dot">.</span></h1>
					{/* <h2>Get better search engine results</h2> */}
					<h2>Gain more search engine traffic</h2>
					<p className="help">Enter your website <span className="hide--mobile">address below </span>to get your <span className="hide--mobile">free </span>report</p>
					<Search />
				</div>
				<div className="preview"><img src={preview} alt="" /></div>
				<canvas id="confetti"></canvas>
			</div>
		)
	}
}

export default App;
